import { disableScroll, enableScroll} from '../utils/scroll';
export default class Accordion {
  // default accordion selectors
  panelSelector = '.acc-item';
  activePanelSelector = '.acc-item.active';
  headSelector = '.acc-item__title-wrap';
  contentSelector = '.acc-item__content';
  wrapperSelector = '.section-acc__items';
  disclaimerPopup = document.querySelector('.legal-disclaimer');
  targetLinkDisclaimer = null;
  paddingSize = 0;

  // store active items to reduce number of DOM interactions
  activeItems = [];

  // timeoutId for resize debounce
  timeoutId = null;

  constructor({
                panelSelector,
                activePanelSelector,
                headSelector,
                contentSelector,
                wrapperSelector,
                paddingSize,
                disclaimerPopup,
                targetLinkDisclaimer,
              } = {}) {
    // redefine options
    if (panelSelector) this.panelSelector = panelSelector;
    if (activePanelSelector) this.panelSelector = activePanelSelector;
    if (headSelector) this.headSelector = headSelector;
    if (contentSelector) this.contentSelector = contentSelector;
    if (wrapperSelector) this.wrapperSelector = wrapperSelector;
    if (paddingSize) this.paddingSize = paddingSize;
    if (disclaimerPopup) this.disclaimerPopup = disclaimerPopup;
    this.targetLinkDisclaimer = targetLinkDisclaimer;
  }

  initListeners() {
    document.addEventListener('click', (e) => this.handleClick(e));
    document.addEventListener("keyup", (e) => {
      if(e.key === 'Escape' && this.disclaimerPopup?.classList.contains('show')) {
        this.disclaimerPopup.classList.remove('show')
        enableScroll()
      }
    });
  }

  handleClick(e) {
    const useDisclaimere = e.target.closest('.use-disclaimer')
    if(this.disclaimerPopup.classList.contains('show')) {
      if(
        !e.target.closest('.legal-disclaimer__popup-wrapper') ||
        e.target.closest('.legal-disclaimer__close-btn') ||
        e.target.closest('.legal-disclaimer__agree')
      ) {
        this.disclaimerPopup.classList.remove('show')
        enableScroll()
        if(e.target.closest('.legal-disclaimer__agree')) {
          this.targetLinkDisclaimer.classList.add('is-agree')
        }
      }
    }

    if(useDisclaimere && e.target.closest('a') && !e.target.closest('a').classList.contains('is-agree')) {
      this.disclaimerPopup.querySelector('.legal-disclaimer__agree').setAttribute('href', e.target.closest('a').getAttribute('href'))
      e.preventDefault();
      this.targetLinkDisclaimer = e.target.closest('a');
      this.disclaimerPopup.classList.add('show')
      this.disclaimerPopup.scrollTop = 0;
      disableScroll()
    }

    const head = e.target.closest(this.headSelector);
    if (!head) return;
    const wrapper = e.target.closest(this.wrapperSelector)
    const panel = head.closest(this.panelSelector);
    const content = panel.querySelector(this.contentSelector);

    // check if clicked item already active
    const itemIndex = this.activeItems.findIndex((item) => item.panel === panel);

    if (itemIndex !== -1) {
      if (!e.target.closest(this.contentSelector)) {
        panel.classList.remove('active');
        // remove active item from storage
        this.activeItems.splice(itemIndex, 1);
      }
    } else {
      panel.classList.add('active');
      this.handleCloseOtherPanel(wrapper, panel);

      // add active items to storage
      this.activeItems.push({panel, wrapper, content});
    }
  }

  handleCloseOtherPanel(elem, panel) {
    const elemAllPanels = [...elem.querySelectorAll(this.activePanelSelector)];
    this.activeItems = [];
    elemAllPanels.find((activePanel) => {
      if (activePanel !== panel) {
        activePanel.classList.remove('active');
        this.activeItems.splice(this.activeItems.findIndex((item) => item.panel === activePanel), 1);
      }
    });
  }

  handleResize() {
    // use stored active items
    this.activeItems?.forEach((activeItem) => {
      activeItem.content.style.height = 'auto';
      setTimeout(
        () => (activeItem.content.style.height = activeItem.content.scrollHeight + 'px'),
        500
      );
    });
  }

  handleActivePanels() {
    this.activeItems = [...document.querySelectorAll(this.activePanelSelector)];
  }

  init() {
    this.handleActivePanels();
    this.initListeners();
  }
}
